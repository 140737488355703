import { useMediaQuery } from "react-responsive";
import { formatTextCurrency } from "../../../../../utils/helpers";
import cartNote from "../../../../assets/icons/cart-note.svg";
import productImageDefault from "../../../../assets/images/product-default-img.jpg";
import FnbDisplayImageComponent from "../../../../components/fnb-display-image/fnb-display-image.component";
import ComboItem from "./components/ComboItem";
import OptionItem from "./components/OptionItem";
import QuantityItem from "./components/QuantityItem";
import StatusOrderedItem from "./components/StatusOrderedItem/StatusOrderedItem";
import ToppingItem from "./components/ToppingItem";
import "./OrderedOrderDetailItem.scss";
import { tabName } from "../TabsStatusOrder/TabsStatusOrder";

const OrderedOrderDetailItem = (props) => {
  const { cartItem, isPos, statusTabsOrder } = props;
  const isMobile = useMediaQuery({ maxWidth: 575 });
  if (!cartItem) return <></>;
  const isCombo = cartItem?.isCombo;
  /**
   * Total Topping price per unit
   */
  const toppingPrice = isCombo
    ? cartItem?.totalOfToppingPrice / cartItem?.quantity
    : cartItem?.productPrice?.totalOfToppingPrice;

  const priceWithDiscount = isCombo
    ? cartItem?.sellingPrice
    : Boolean(cartItem?.productPrice?.flashSaleId)
      ? cartItem?.productPrice?.priceValue
      : cartItem?.productPrice?.priceAfterDiscountInStore;

  const thumbnail = cartItem.thumbnail;
  const total = () => {
    return ((priceWithDiscount ?? 0) + (toppingPrice ?? 0)) * cartItem?.quantity;
  };

  const renderStatusOrderedItem = () => (statusTabsOrder !== tabName.RECEIPT && cartItem && <StatusOrderedItem statusId={cartItem.statusId} />)

  return (
    <div className="ordered-order-detail-item">
      {isMobile ? (
        <>
          <div className={`checkout-order-item-theme-2`}>
            <div className="order-item-image">
              <FnbDisplayImageComponent
                src={thumbnail}
                srcFallback={productImageDefault}
                isFlashSale={Boolean(cartItem?.productPrice?.flashSaleId)} />
            </div>
            <div className="order-item">
              <div className="order-item-info">
                <div className="name text-line-clamp-2">
                  {renderStatusOrderedItem()}
                  {cartItem?.name}
                  {cartItem?.productPrice?.priceName && `(${cartItem?.productPrice?.priceName})`}
                </div>
              </div>
              <div className="options">
                <OptionItem cartItem={cartItem} />
              </div>
              <ToppingItem cartItem={cartItem} />
              <ComboItem cartItem={cartItem} />
              <div className="order-item-quantity-total">
                <div className="order-item-quantity">
                  <QuantityItem cartItem={cartItem} statusTabsOrder={statusTabsOrder} />
                </div>
                <div className="order-item-total">
                  <div className="total-amount">{formatTextCurrency(Math.round(total()))}</div>
                </div>
              </div>
              {cartItem?.notes && (
                <div className="cartMessageCheckout">
                  <img className="messageIconCheckout" src={cartNote} alt="Note" />
                  <div className="messageNoteCheckout">{cartItem?.notes}</div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`checkout-order-item-theme-2`}>
            <div className="order-item">
              <div className="order-item-info">
                <div className="order-item-image">
                  <FnbDisplayImageComponent
                    src={thumbnail}
                    srcFallback={productImageDefault}
                    isFlashSale={!isPos ? Boolean(cartItem?.isFlashSale) : false}
                    isOutOfStock={cartItem?.isOutOfStock}
                  />
                </div>
                <div className="name-info">
                  <div className="name text-line-clamp-2">
                    {renderStatusOrderedItem()}
                    {cartItem?.name || cartItem?.dataDetails?.comboPricingName}
                    {cartItem?.productPrice?.priceName && `(${cartItem?.productPrice?.priceName})`}
                  </div>
                  {!cartItem?.isCombo && (
                    <div className="options">
                      <OptionItem cartItem={cartItem} />
                    </div>
                  )}
                </div>
              </div>

              <div className="order-item-quantity">
                <QuantityItem cartItem={cartItem} statusTabsOrder={statusTabsOrder} />
              </div>

              <div className="order-item-total">
                <div className="total-amount">{formatTextCurrency(Math.round(total()))}</div>
              </div>
            </div>
            <ToppingItem cartItem={cartItem} />
            <ComboItem cartItem={cartItem} />
            {cartItem?.notes && (
              <div className="cartMessageCheckout">
                <img className="messageIconCheckout" src={cartNote} alt="Note" />
                <div className="messageNoteCheckout">{cartItem?.notes}</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderedOrderDetailItem;
