import { Collapse } from "antd";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { posDiscountCodesSelector } from "../../../modules/order/order.reducers";
import posCartService from "../../../services/pos/pos-cart.services";
import reduxService from "../../../services/redux.services";
import { formatTextCurrency, formatTextNumber } from "../../../utils/helpers";
import { RemoveDiscountCodeIcon } from "../../assets/icons.constants";
import { enumOrderType } from "../../constants/enums";
import NameAndValuePopover from "../name-and-value-popup/NameAndValuePopover";
import { ReactComponent as ArrowDownIcon } from "./arrow-down-icon.svg";
import "./pos-order-checkout-summary.style.scss";

export const POSOrderCheckoutSummary = forwardRef((props, ref) => {
  const { isDefault, isPos, fontFamily, orderDetailByIdData, fetchData, applyDiscountCodes, totalCartItems } = props;
  const [t] = useTranslation();
  const shippingFee = useSelector((state) => state?.session?.orderInfo?.shippingFee ?? null);
  const discountCodesPos = useSelector(posDiscountCodesSelector); // Todo use
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);

  const panelStyle = {
    background: "#F9F9F9",
    border: "none",
    fontFamily: fontFamily,
  };
  const fontweightchange = isDefault ? 600 : 400;
  const translatedData = {
    subTotal: t("checkOutPage.subTotal", "Subtotal"),
    item: t("checkOutPage.items", "items"),
    discount: t("checkOutPage.discount", "Discount"),
    feeTax: t("checkOutPage.feeAndTax", "Fee & Tax"),
    total: t("checkOutPage.total", "Total"),
    shippingFee: t("checkOutPage.shippingFee", "Shipping Fee"),
  };

  useImperativeHandle(ref, () => ({}));

  const handleRemoveDiscountCode = async (code) => {
    let POSDiscountCodes = reduxService.getPOSDiscountCodes();
    const indexExist = POSDiscountCodes?.findIndex((discountCode) => discountCode === code);
    if (indexExist === -1) {
      POSDiscountCodes = [...POSDiscountCodes, code];
    } else {
      POSDiscountCodes.splice(indexExist, 1);
    }
    applyDiscountCodes(POSDiscountCodes, false);
    await fetchData(orderDetailByIdData?.id);
  };

  const originalPrice = orderDetailByIdData?.originalPrice;
  const discountAmount = orderDetailByIdData?.totalDiscountAmount;
  const totalFee = orderDetailByIdData?.totalFee ?? 0;
  const totalTax = orderDetailByIdData?.totalTax ?? 0;
  const totalFeeAndTax = totalFee + totalTax;
  const totalAmount = orderDetailByIdData?.totalAmount;

  const shouldShowIconFeeTax = totalFeeAndTax > 0;
  const hasDiscounts = discountAmount > 0;

  return (
    <div className="theme2-checkout-summary">
      <div className="checkout-summary-container">
        {/* Subtotal */}
        <div className="sub-total">
          <span>{`${translatedData.subTotal} (${totalCartItems} ${translatedData.item})`}</span>
          <span>{formatTextCurrency(originalPrice)}</span>
        </div>

        {/* Discount */}
        <Collapse className="checkout-summary-detail-collapse" ghost>
          <Collapse.Panel
            collapsible="disabled"
            header={
              <div className="discount">
                <div className="text-box">
                  <span style={{ fontWeight: fontweightchange }}>{translatedData.discount}</span>
                  {hasDiscounts && (
                    <div className="icon-box">
                      <NameAndValuePopover
                        className="discount-popover discount-popover-summary-order"
                        placement="bottom"
                        data={posCartService.mappingOrderDetailToPromotionPopupData(orderDetailByIdData)}
                        overlayInnerStyle={{ fontFamily }}
                        button={<ArrowDownIcon />}
                      />
                    </div>
                  )}
                </div>

                <span>-{formatTextCurrency(Math.round(discountAmount))}</span>
              </div>
            }
            style={panelStyle}
            showArrow={false}
          >
            <p>{t("messages.discountFeeTaxHereInformation")}</p>
          </Collapse.Panel>
        </Collapse>

        {discountCodesPos && (
          <div className="discount-code-tags">
            {discountCodesPos?.map((item) => {
              return (
                <div className="discount-code-tag">
                  {item}
                  <div className="remove-icon" onClick={() => handleRemoveDiscountCode(item)}>
                    <RemoveDiscountCodeIcon />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Fee & tax */}
        <Collapse className="checkout-summary-detail-collapse" ghost>
          <Collapse.Panel
            collapsible="disabled"
            header={
              <div className="fee-tax">
                <div className="text-box">
                  <span style={{ fontWeight: fontweightchange }}>{translatedData.feeTax}</span>
                  {shouldShowIconFeeTax && (
                    <div className="icon-box">
                      <NameAndValuePopover
                        className="fee-and-tax-popover fee-and-tax-popover-summary-order"
                        placement="bottom"
                        data={posCartService.mappingOrderDetailToFeeAndTaxPopupData(orderDetailByIdData)}
                        overlayInnerStyle={{ fontFamily }}
                        button={<ArrowDownIcon />}
                      />
                    </div>
                  )}
                </div>

                <span>{formatTextCurrency(totalFeeAndTax)}</span>
              </div>
            }
            style={panelStyle}
            showArrow={false}
          >
            <p>{t("messages.discountFeeTaxHereInformation")}</p>
          </Collapse.Panel>
        </Collapse>

        {deliveryAddress?.orderType === enumOrderType.DELIVERY && !isPos && (
          <div className="shipping-fee">
            <span style={{ fontWeight: fontweightchange }}>{translatedData.shippingFee}</span>
            <span>{formatTextCurrency(shippingFee)}</span>
          </div>
        )}
        <div className="line"></div>

        {/* Total */}
        <div className="total">
          <span>{translatedData.total}</span>
          <span>{formatTextCurrency(Math.round(totalAmount))}</span>
        </div>
      </div>
    </div>
  );
});
